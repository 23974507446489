import { Controller } from "stimulus";
import lodash from "lodash";

export default class extends Controller {
  static targets = [
    "soil",
    "depth",
    "length",
    "width",
    "maxDepthViolation",
    "cornerPostSpacing",
    "strutSpacing",

    "totalPanelsNeeded",
    "maxPanelSize",
    "totalStrutsNeeded",
    "highArchSpacing",
    "highArchStrutNeeded",

    "twoSideStrutCount",
    "twoSidePanelCount",

    "threeSideStrutCount",
    "threeSidePanelCount",

    "fourSideStrutCount",
    "fourSidePanelCount",

    "archedTwoSideStrutCount",
    "archedTwoSidePanelCount",

    "archedThreeSideStrutCount",
    "archedThreeSidePanelCount",

    "standardAlertBox",
    "standardAlertMessage",
    "standardTable",

    "archAlertBox",
    "archAlertMessage",
    "archTable",
  ];
  static values = {
    maxdepthlookup: Array,
    cornerpostlookup: Array,
    strutlookup: Array,

    higharchlookup: Array,
  };

  static classes = ["exceeded"];

  connect() {
    console.log("Hello, Stimulus!", this.element);
    console.log("targets", this.soilTarget, this.depthTarget, this.lengthTarget, this.widthTarget);
    console.log("values", this.maxdepthlookupValue);
    this.calculate();
  }

  calculate() {
    const maxDepthsByWidth = _.find(this.maxdepthlookupValue, { length: this.maxSide });
    const maxDepthActual = _.parseInt(maxDepthsByWidth[this.soilTarget.value]);
    const targetDepth = _.parseInt(this.depthTarget.value);
    const cornerPostByType = _.find(this.cornerpostlookupValue, { panel: this.maxSide, depth: targetDepth.toString() });
    const cornerPostActual = _.parseInt(cornerPostByType[this.soilTarget.value]);
    const strutByType = _.find(this.strutlookupValue, { panel: this.maxSide, depth: targetDepth.toString() });
    const strutActual = _.parseInt(strutByType[this.soilTarget.value]);
    const highArchByType = _.find(this.higharchlookupValue, { length: this.maxSide });
    const highArchActual = 6;
    const highArchMaxDepth = _.parseInt(highArchByType[this.soilTarget.value]);
    const panelsNeeded = _.ceil(targetDepth / 2);

    this.maxPanelSizeTarget.innerHTML = `${this.maxSide} ft`;

    if (targetDepth > maxDepthActual) {
      const message = `You can't dig deeper than ${maxDepthActual} feet in ${this.soilTarget.value} soil with ${this.maxSide} ft panels`;

      this.standardTableTarget.classList.add("hidden");
      this.standardAlertBoxTarget.classList.remove("hidden");
      this.standardAlertMessageTarget.innerHTML = message;
      // this.highArchSpacingTarget.innerHTML = errMsg;
      // this.highArchSpacingTarget.classList.add(this.exceededClass)
      // this.highArchStrutNeededTarget.innerHTML =  errMsg;
      // this.highArchStrutNeededTarget.classList.add(this.exceededClass)
    } else {
      this.standardTableTarget.classList.remove("hidden");
      this.standardAlertBoxTarget.classList.add("hidden");
      // this.highArchSpacingTarget.classList.remove(this.exceededClass)
      // this.highArchStrutNeededTarget.classList.remove(this.exceededClass)
    }

    let totalStrutsUsed = 2;
    let remainingDepth = targetDepth;
    remainingDepth -= cornerPostActual * 2;
    let neededStruts = 0;
    if (remainingDepth > 0) {
      console.log("remaining", remainingDepth);
      console.log("div", remainingDepth / strutActual);
      neededStruts = _.ceil(remainingDepth / strutActual);
      console.log("modulus ", remainingDepth % strutActual);
      if (remainingDepth % strutActual === 0) {
        console.log("perfect fit ");
        neededStruts -= 1;
      }
    }
    totalStrutsUsed += neededStruts;

    remainingDepth = targetDepth;
    remainingDepth -= 7;
    let archStrutsTotal = 0;
    console.log('after arch', archStrutsTotal, remainingDepth, targetDepth)
    if (remainingDepth > 0) {
      remainingDepth -= cornerPostActual;
      archStrutsTotal += 1;
      console.log('after corner post', archStrutsTotal, remainingDepth, targetDepth)
      if (remainingDepth > 0) {
        console.log(remainingDepth, strutActual)
        let moreArchStruts = _.floor(remainingDepth / strutActual);
        if (remainingDepth % strutActual === 0) {
          console.log("prefect arch fit ");
          moreArchStruts -= 1;
        }

        archStrutsTotal += moreArchStruts;
        console.log('after corner post', archStrutsTotal, remainingDepth, targetDepth)
      }
    }

    const result = { maxDepthActual, cornerPostActual, strutActual, totalStrutsUsed, neededStruts };
    console.log("RESULT", result);
    this.cornerPostSpacingTarget.innerHTML = cornerPostActual + " ft";
    this.strutSpacingTarget.innerHTML = strutActual + " ft";

    if (highArchMaxDepth >= targetDepth) {
      this.archTableTarget.classList.remove("hidden");
      this.archAlertBoxTarget.classList.add("hidden");
    } else {
      const errMsg = `You can't use high arch's deeper than ${highArchMaxDepth} feet in ${this.soilTarget.value} soil with ${this.maxSide} ft panels`;
      this.archTableTarget.classList.add("hidden");
      this.archAlertBoxTarget.classList.remove("hidden");
      this.archAlertMessageTarget.innerHTML = errMsg;
    }

    // table output
    this.twoSidePanelCountTarget.innerHTML = panelsNeeded * 2;
    this.twoSideStrutCountTarget.innerHTML = totalStrutsUsed * 2;
    this.threeSidePanelCountTarget.innerHTML = panelsNeeded * 3;
    this.threeSideStrutCountTarget.innerHTML = totalStrutsUsed * 1;
    this.fourSidePanelCountTarget.innerHTML = panelsNeeded * 4;
    this.fourSideStrutCountTarget.innerHTML = totalStrutsUsed * 0;

    if (highArchMaxDepth >= targetDepth) {
      this.archedTwoSidePanelCountTarget.innerHTML = panelsNeeded * 2;
      this.archedTwoSideStrutCountTarget.innerHTML = archStrutsTotal * 2;

      this.archedThreeSidePanelCountTarget.innerHTML = panelsNeeded * 3;
      this.archedThreeSideStrutCountTarget.innerHTML = archStrutsTotal * 1;
    }
  }

  get maxSide() {
    return lodash.max([parseInt(this.lengthTarget.value), parseInt(this.widthTarget.value)]).toString();
  }
}
