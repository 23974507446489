import { Application } from "stimulus";
import Lightbox from "stimulus-lightbox";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import smoothscroll from "smoothscroll-polyfill";
import Turbo from "@hotwired/turbo";

import "index.scss";
import "lightgallery.js/dist/css/lightgallery.min.css";

// kick off the polyfill!
smoothscroll.polyfill();

// Import all javascript files from src/_components
const componentsContext = require.context("bridgetownComponents", true, /.js$/);
componentsContext.keys().forEach(componentsContext);

const application = Application.start();
application.register("lightbox", Lightbox);
const context = require.context("./controllers", true, /.js$/);
application.load(definitionsFromContext(context));
