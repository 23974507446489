import { Controller } from "stimulus";

const SPEED = 400

export default class extends Controller {
  static values = { flash: Boolean, class: String }
  initialize() {
    this.scroll = this.scroll.bind(this);
  }

  connect() {
    this.element.addEventListener("click", this.scroll);
    
  }

  scroll(event) {
    const id = this.element.hash.replace(/^#/, "");
    const target = document.getElementById(id);
    if (target && target.scrollIntoView) {
      event.preventDefault();
      target.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        window.location.hash = id;
        if (this.flashValue) {
          this.flash(id)
        }
      }, 250);
    } else {
      console.log("No target or unsupported", target, id);
    }
  }

  flash(id) {
    const target = document.getElementById(id);
    if (target) {
      target.classList.add(this.classValue);
      setTimeout(() => {
        target.classList.remove(this.classValue);
        setTimeout(() => {
          target.classList.add(this.classValue);
          setTimeout(() => {
            target.classList.remove(this.classValue);
          }, SPEED);

        }, SPEED);
      }, SPEED);
    }
  }
}
